
export default {
  props: {
    size: {
      type: Number,
      default: 5,
    },
    position: {
      type: String,
      default: 'top-4 right-4',
    },
    background: {
      type: String,
      default: 'bg-white bg-opacity-20',
    },
    color: {
      type: String,
      default: 'text-black dark:text-white',
    },
  },
}
